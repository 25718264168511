import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { Button, Form, Col } from "react-bootstrap"
import { formGroup, formContainer } from "./contactus.module.css"
import { Formik } from "formik"
import * as yup from 'yup';

const schema = yup.object().shape({
  firstName: yup.string().required('First Name is required'),
  lastName: yup.string().required('Last Name is required'),
  email: yup.string().required('Email is required').email('Please enter a valid email'),
  phone: yup.string().optional().length(10, "Please enter your full phone number"),
  message: yup.string().required("Please desrcibe your request").min(10, "Please desrcibe your request")
});


const ContactusPage = ({ data, services }) => {
  const isBrowser = typeof window !== 'undefined';
  let apiName = '#'
  if (isBrowser) {
    apiName = `https://api.${window.location.host.replace('www.', '')}/webform/contactus`;
  }

  return (
    <Layout>
      <Seo title="Contact Us" />
      <div className={formContainer + " page-container"}>
        <h2 className="page-title">Contact Us</h2>
        <Formik
          initialValues={{ firstName: '', lastName: '', email: '', phone: '', message: '' }}
          validationSchema={schema}
          onSubmit={(values, actions) => {
            fetch(apiName, {
              method: 'POST',
              body: JSON.stringify(values)
            }).then(res => {
              actions.resetForm();
            }).catch(err => {
              console.log(err)
            }).finally(() => {
              actions.setSubmitting(false);
            })
          }}
        >{({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Form.Row>
              <Col md={6}>
                <Form.Group className={formGroup} controlId="formFirstName">
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="firstName"
                    placeholder="John"
                    value={values.firstName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={touched.firstName && !errors.firstName}
                    isInvalid={touched.firstName && errors.firstName}
                  />
                  <Form.Control.Feedback type="invalid">{errors.firstName}</Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className={formGroup} controlId="formLastName">
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="lastName"
                    placeholder="Doe"
                    value={values.lastName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={touched.lastName && !errors.lastName}
                    isInvalid={touched.lastName && errors.lastName}
                  />
                  <Form.Control.Feedback type="invalid">{errors.lastName}</Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Form.Row>
            <Form.Row>
            <Col md={6}>
            <Form.Group className={formGroup} controlId="formEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                name="email"
                placeholder="john@email.com"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                isValid={touched.email && !errors.email}
                isInvalid={touched.email && errors.email}
              />
              <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
            </Form.Group>
            </Col>
            <Col md={6}>
            <Form.Group className={formGroup} controlId="formPhone">
              <Form.Label>Phone</Form.Label>
              <Form.Control
                type="phone"
                name="phone"
                placeholder="7702370098"
                value={values.phone}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={touched.phone && !!errors.phone}
              />
              <Form.Control.Feedback type="invalid">{errors.phone}</Form.Control.Feedback>
            </Form.Group>
            </Col>
            </Form.Row>
            <Form.Group className={formGroup} controlId="formDetails">
              <Form.Label>Details</Form.Label>

              <Form.Control
                as="textarea"
                rows="5"
                name="message"
                value={values.message}
                onChange={handleChange}
                onBlur={handleBlur}
                isValid={touched.message && !errors.message}
                isInvalid={touched.message && !!errors.message}
              />
              <Form.Control.Feedback type="invalid">{errors.message}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className={formGroup}>
              <Button type="submit" disabled={isSubmitting}>Submit</Button>
            </Form.Group>
          </Form>
        )}
        </Formik>
      </div>
    </Layout>
  )
}

export default ContactusPage
